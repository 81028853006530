hr {
  border-bottom: 1px solid black;
}

.fulfillment-footer {
  border-top: 1px solid var(--redo-colors-border-border-primary);
}

.product {
  &:not(:last-child) {
    border-bottom: 1px solid var(--redo-colors-border-border-primary);
    padding-bottom: 24px;
  }

  .product-image {
    aspect-ratio: var(--image-aspect-ratio-override, 1/1);
    object-fit: cover;
    width: 120px;
    height: 120px;
    margin-right: 16px;
    border-radius: 4.75px;

    &:not([src]) {
      background-color: #d7d7d7;
    }
  }

  .product-title {
    min-width: 45%;
  }
}
