@import "./component-colors-light.module.css";
@import "./component-colors-dark.module.css";

/* These colors are all deprecated. Favor the ones in component-colors-*.css */
:root,
:host {
  --redo-border-color: #eee;
  --redo-border-color-dark: #d6d6d6;
  --redo-contrast-high-border-color: #d7d7d7;
  --redo-contrast-low-text-color: #777;
  --redo-color-text-primary: #141414;
  --redo-color-text-secondary: #424242;
  --redo-color-text-tertiary: #525252;
  --redo-color-text-placeholder-subtle: #d6d6d6;
  --redo-accent-color-background: #fafafa;
  --redo-accent-color: #181818;
  --redo-bg-primary_alt: #fcfcfc;
  --redo-error-color: #ff5151;
  --redo-alert-background: #fe4a4c;
  --redo-home-text-color: #000000;
  --redo-nav-background-active: #292929;
  --redo-nav-popup-background: #424242;
  --redo-nav-color: #181818;
  --redo-primary-button-color: black;
  --redo-tertiary-button-color: #3e28ad;
  --redo-neutral-yellow-color: #fffaeb;
  --redo-neutral-blue-color: #eff8ff;
  --redo-utility-gray: #a3a3a3;
  --redo-utility-brand: #8671ed;
  --redo-dark-gray: #555760;
  --redo-light-gray: #a6a9ba;
  --redo-extra-light-gray: #d9d9d9;
  --redo-fg-brand-primary-color: #4d35c6;
  --redo-primary-color: #5f45e2;
  --redo-primary-light-color: #8773e9;
  --redo-primary-extra-light-color: #ebe9fd;
  --redo-skeleton-color: #ddd;
  --redo-surface-danger-color: #ff6550;
  --redo-surface-danger-light-color: #fef3f2;
  --redo-border-danger-light-color: #fecdca;
  --redo-border-success-light-color: #abefc6;
  --redo-border-warning-light-color: #fedf89;
  --redo-surface-success-color: #ecfdf3;
  --redo-text-success-color: #067647;
  --redo-text-warning-color: #b54708;
  --redo-text-danger-color: #b42318;
  --redo-success-secondary-color: #17b26a;
  --redo-error-secondary-color: #f04438;
  --redo-surface-dark-color: #ededed;
  --redo-surface-light-color: white;
  --redo-surface-light-primary-color: #ebe9fd;
  --redo-surface-medium-color: #f8f8f8;
  --redo-text-color: #fff;
  --redo-warning-color: #fef0c7;
  --redo-warning-color-dark: #f79009;
  --redo-shadow-color-primary: #10182814;
  --redo-shadow-color-secondary: #10182808;
}

/* :root[data-theme="dark"] {
  --redo-component-colors-components-buttons-primary-button-primary-bg: white;
} */

/*

<html data-theme="light">
<html data-theme="dark">

// To switch to light theme
document.documentElement.setAttribute('data-theme', 'light');

// To switch to dark theme
document.documentElement.setAttribute('data-theme', 'dark');

body {
  color: var(--redo-colors-text-text-primary-900);
  background-color: var(--redo-colors-background-bg-primary);
}

*/

.bg-color-none {
  background-color: transparent;
}

.bg-color-primary {
  background-color: var(--redo-colors-background-bg-primary);
}

.bg-color-secondary {
  background-color: var(--redo-colors-background-bg-secondary);
}

.bg-color-secondary-alt {
  background-color: var(--redo-colors-background-bg-secondary_alt);
}

.bg-color-tertiary {
  background-color: var(--redo-colors-background-bg-tertiary);
}

.bg-color-quaternary {
  background-color: var(--redo-colors-background-bg-quaternary);
}

.bg-color-brand-primary {
  background-color: var(--redo-colors-background-bg-brand-primary);
}

.bg-color-brand-secondary {
  background-color: var(--redo-colors-background-bg-brand-secondary);
}

.bg-color-error-primary {
  background-color: var(--redo-colors-background-bg-error-primary);
}

.bg-color-error-secondary {
  background-color: var(--redo-colors-background-bg-error-secondary);
}

.bg-color-error-solid {
  background-color: var(--redo-colors-background-bg-error-solid);
}

.bg-color-warning-primary {
  background-color: var(--redo-colors-background-bg-warning-primary);
}

.bg-color-warning-secondary {
  background-color: var(--redo-colors-background-bg-warning-secondary);
}

.bg-color-warning-solid {
  background-color: var(--redo-colors-background-bg-warning-solid);
}

.bg-color-success-primary {
  background-color: var(--redo-colors-background-bg-success-primary);
}

.bg-color-success-secondary {
  background-color: var(--redo-colors-background-bg-success-secondary);
}

.bg-color-success-solid {
  background-color: var(--redo-colors-background-bg-success-solid);
}
