.drawer {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: var(--redo-colors-background-bg-primary) !important;
}

.inputs {
  padding: 20px;
}

.buttons {
  padding: 0 20px;
  margin-bottom: 80px; /* to make sure the buttons are not hidden by the chat widget cause it's dumb and won't move */
}
