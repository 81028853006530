.comment-link {
  color: var(
    --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-fg
  );
  cursor: pointer;
}

.comment-profile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
