.button-content {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  justify-content: center;
}

.conversations-card-footer {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.conversations-card-header {
  padding: 24px 24px 16px 24px;
  width: 100%;
}

.icon {
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
}
