.container {
  flex: 1;
  align-self: flex-start;
}

.main-card {
  width: 774px;
}

.parcels {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 8px;
}

.parcel {
  display: flex;
  justify-content: space-between;
  height: 72px;
  border-bottom: 1px solid var(--redo-colors-border-border-secondary);
}

.main-info {
  display: flex;
  gap: 12px;
  align-items: center;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.text {
}

.name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.dimensions {
  color: var(--redo-colors-text-text-tertiary-600);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  position: relative;
}

.button-content {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.actions-button {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color var(--redo-state-duration);

  &:hover {
    background-color: var(--redo-colors-background-bg-primary_hover);
  }
}

.modal {
  width: 600px !important;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modal-button {
  flex: 1;
}

.edit-dimensions {
  display: flex;
  gap: 8px;
  align-items: start;
}

.edit-weight {
  display: flex;
  gap: 8px;
  align-items: start;
}

.edit-unit {
  min-width: 64px;
  margin-top: 35px;
}

.modal-actions {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 20px;
}

.checkbox-text {
  color: var(--redo-colors-text-text-secondary-700);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.checkbox-subtext {
  color: var(--redo-colors-text-text-tertiary-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 32px;
}

.pill-container {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  align-items: center;
}

.carrier-header {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--redo-colors-text-text-primary-900);
}

.carrier-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.carrier-parcels-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.carrier-parcel-name {
  color: var(--redo-colors-text-text-primary-900);
  font-size: var(--redo-body-small-text-size);
  font-weight: 500;
}

.carrier-parcel-dimensions {
  color: var(--redo-colors-text-text-tertiary-600);
  font-size: var(--redo-body-small-text-size);
  font-weight: 400;
}

.authenticate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}
