.button-content {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
  height: 21px;
}

.card-list {
  overflow-y: auto;
  flex: 1;
  scrollbar-width: thin;
}

.center-content {
  display: flex;
  flex: 1 1 0%;
  min-width: 0%;
}

.chevron {
  width: 20px;
  height: 20px;
}

.conversation-detail-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-width: 0;
  flex: 1 1 0%;
}

.full-height {
  --banner-height: 0px;
  height: calc(100vh - var(--banner-height));
  &.banner-shown {
    --banner-height: 52px;
  }
}

.detail-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: calc(var(--redo-spacing) * 3);

  .table-summary-container {
    overflow-wrap: break-word;
  }
}

.dropdown-title {
  font-weight: bold;
  padding: 8px;
}

.hidden {
  display: none;
}

.icon {
  height: 16px;
  width: 16px;
}

.portal-buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  margin-left: auto;

  &.align-center {
    align-items: center;
  }

  .dropdown-button {
    div {
      height: 100%;
    }

    button {
      height: 100%;
      padding: 6px 10px;

      div {
        align-content: center;
      }
    }
  }

  .three-dots-button {
    div {
      height: 100%;
    }

    button {
      height: 100%;
      width: 36px;

      div {
        align-content: center;
      }
    }
  }
}

.table-summary-container {
  padding: 0;
  background-color: var(--redo-colors-background-bg-primary);
  display: flex;
  flex-direction: column;
  border-right: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.table-summary-header {
  display: flex;
  flex-direction: row;
  background-color: var(--redo-colors-background-bg-primary);
  z-index: 2;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  height: 44px;
}

.wide {
  /* Don't grow to fit children */
  min-width: 0px;

  /* This margin should cancel out the margin in web/src/page.module.css.
   * It would be cleaner to hide the page padding based on url, but that
   * would mean there's a moment when first loading the page where we're
   * on the table view, but there is no padding because the url contains
   * the active conversation query param.
   * That only happens when you refresh the page while there's an active
   * conversation or you load a link to a specific conversation.
  */
  margin: -24px -48px -32px -48px;
  display: flex;

  flex: 1 1 0%;
}

.panel {
  width: 200px;
  min-width: 200px;
  color: var(--redo-colors-text-text-secondary-700);
  &.right {
    width: 240px;
    min-width: 240px;
  }
}

.close-panel-button {
  width: 38px;
  height: 38px;
  /* To match height of the AI summary button */
  padding: 0px !important;
}

.conversation-context-container {
  background-color: var(--redo-colors-background-bg-primary);
  border-left: 1px solid var(--redo-colors-border-border-secondary);
}
