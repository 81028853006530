.dropdown-group {
  width: 100%;
}

.dropdown {
  width: 100%;
}

.dropdown-items {
  transition: height var(--redo-collapse-duration);
  overflow: hidden;
}

.item {
  cursor: pointer;
  height: 72px;
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  transition: background-color var(--redo-state-duration);
}

.border-bottom {
  border-bottom: var(--redo-colors-border-border-primary) 1px solid;
}

.icon-container {
  width: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity var(--redo-state-duration);
}

.chevron {
  transition: transform var(--redo-collapse-duration);
}
