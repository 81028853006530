.container {
  width: 100%;

  .main {
    width: 100%;
  }

  &,
  & > div,
  & > div > div {
    gap: calc(var(--redo-spacing) * 3);
  }
}

.description-details {
  gap: 24px 138px;
  width: 100%;
  border-bottom: 1px solid var(--redo-colors-border-border-primary);
  height: fit-content;
  padding: 24px 0;

  dt {
    font-size: var(--redo-body-small-text-size);
  }

  dd {
    font-weight: bold;
  }
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
}

.non-bold {
  font-weight: 400;
}

.lg-text {
  font-size: var(--redo-body-large-text-size);
}

.med-text {
  font-size: var(--redo-body-medium-text-size);
}

.sm-text {
  font-size: var(--redo-body-small-text-size);
}

.xs-text {
  font-size: var(--redo-body-xsmall-text-size);
}

.bottom-padded {
  padding-bottom: 5px;
}

.card {
  background-color: var(--redo-colors-background-bg-primary);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  border-radius: var(--redo-corner-large-radius);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  position: relative;
}

.package-select-action-button {
  width: 200px;
}

.plus-icon {
  margin-right: 4px;
}

.button-icon {
  vertical-align: text-bottom;
  margin-left: var(--redo-spacing);
}

.add-package-button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-weight {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: start;
}

.edit-unit {
  min-width: 64px;
  margin-top: 30px;
}

.edit-weight-container {
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: start;
}

.button-content {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.button-bar {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}
