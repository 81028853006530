.active-wrapper {
  min-width: 12px;
  max-width: 12px;
}

.button {
  color: white;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
  outline: var(--redo-border-width) solid transparent;
  position: relative;
  align-items: center;
  display: flex;
  flex-grow: 1;
  color: var(--redo-colors-foreground-fg-disabled);
  transition:
    background-color var(--redo-state-duration),
    padding-left var(--redo-collapse-duration),
    padding-right var(--redo-collapse-duration),
    outline var(--redo-state-duration);

  &.no-background {
    background-color: var(
      --redo-component-colors-components-application-navigation-nav-item-bg-zero
    ) !important;
  }
  &:focus,
  &.active {
    background: var(--redo-nav-background-active);
    color: white;
    font-weight: 400;
  }

  &.option {
    background: #313131;
    justify-content: center;
    margin-top: auto;

    &:hover {
      background: var(
        --redo-component-colors-components-application-navigation-nav-item-bg-hover
      );
    }

    .label {
      flex: unset;
    }
  }

  &:not(.option) {
    &:not(.active):hover {
      background: var(
        --redo-component-colors-components-application-navigation-nav-item-bg-active
      );
    }

    &:not(.active):active {
      background: var(
        --redo-component-colors-components-application-navigation-nav-item-bg-active
      );
      outline-color: var(
        --redo-component-colors-components-application-navigation-nav-item-bg-active
      );
      transition: none;
    }
  }
}

.divider-section .divider {
  margin: 0;
}

.dropdown.expand .dropdown-chevron {
  transform: rotate(0);
}

.dropdown-chevron {
  height: var(--redo-icon-medium-size);
  min-height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
  min-width: var(--redo-icon-medium-size);
  margin-left: auto;
  transform: rotate(-180deg);
  transition: transform var(--redo-collapse-duration);
}

.menu {
  background: var(--redo-nav-color);
  color: white;
  padding-left: 8px;
  width: 180px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.menu-header {
  composes: truncate from "./wrap.module.css";
  font-style: italic;
  padding: 12px 8px;
  margin: 0 8px;

  &:not(:last-child) {
    border-bottom: var(--redo-border-width) solid rgba(221, 221, 221, 0.3);
  }
}

.menu-item {
  composes: truncate from "./wrap.module.css";
  cursor: pointer;
  display: flex;
  padding: 8px 18px;
  align-items: center;

  &:hover {
    color: var(--redo-nav-background-active);
  }

  &:nth-child(2) {
    margin-top: 6px;
  }

  &:last-child {
    margin-bottom: 6px;
  }
}

.nav {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: width var(--redo-collapse-duration);
  width: var(--side-nav-width);
  z-index: 20;
  scrollbar-width: none;
}

.options-container {
  overflow: hidden;
  transition: height var(--redo-collapse-duration);
}

.options {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 4px;
}

.page {
  /**
   * This width should be kept in sync with theme/misc.css
   */
  --side-nav-width: 200px;
  height: 100%;
  width: 100%;
  background: var(--redo-colors-background-bg-secondary);
  flex: 1;
  display: flex;
  flex-direction: column;

  &.collapsed {
    --side-nav-width: 61px;

    .button {
      padding-left: 12px;
      padding-right: 12px;
    }

    .logo {
      align-self: center;
    }

    .section {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@media print {
  .page {
    --side-nav-width: 0;
  }
  @page {
    size: 400mm 210mm;
  }
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}

.section {
  background: var(
    --redo-component-colors-components-application-navigation-nav-bg
  );
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
  transition: padding-left var(--redo-collapse-duration) padding-right
    var(--redo-collapse-duration);
}

.subsection {
  padding: calc(var(--redo-spacing) * 3);
}

.logo-section {
  background: var(
    --redo-component-colors-components-application-navigation-nav-bg
  );
}

.section:last-child {
  flex: 1;
}

.icon {
  display: flex;
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  transition: transform var(--redo-state-duration);

  &.rotate {
    transform: rotate(180deg);
  }
}

.no-icon {
  display: flex;
  height: 16px;
  padding-left: 8px;
  width: 16px;
  justify-content: center;
  align-items: center;
}

.dropdown-children {
  padding-left: 8px;
}

.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  composes: truncate from "./wrap.module.css";
}

.label-with-bubble {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  width: 100%;
  justify-content: space-between;
  composes: truncate from "./wrap.module.css";
  &.active {
    font-weight: 600;
  }
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: var(--side-nav-width);
  width: calc(100% - var(--side-nav-width));
  max-width: calc(100vw - var(--side-nav-width));
  transition:
    margin-left var(--redo-collapse-duration),
    width var(--redo-collapse-duration);

  &.fullscreen {
    margin-left: 0;
    width: 100%;
    max-width: 100vw;
  }
}

.subitem {
  align-items: center;
  display: flex;
  gap: calc(var(--redo-spacing) * 0.5);
  padding: 12px;
  position: relative;
  margin-left: 36px;
  border-radius: var(--redo-corner-small-radius);
  color: var(--redo-colors-foreground-fg-disabled);
  cursor: pointer;
  &.settings {
    padding: 8px 12px;
  }
  &:hover,
  &:focus,
  &.active {
    color: white;
    background-color: var(
      --redo-component-colors-components-application-navigation-nav-item-bg-active
    );
    font-weight: 600;
  }
}

.subitem-line-container {
  overflow: hidden;
  position: absolute;
  left: 18px;
  top: -28px;
}

.subitem-line {
  --radius: 8px;
  border: var(--redo-border-width) solid white;
  border-radius: var(--radius);
  width: 32px;
  height: 58px;
  position: relative;
  top: calc(var(--radius) * -1);
  right: calc(var(--radius) * -1);
}

.subitem-label {
  font-size: var(--redo-body-medium-text-size);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color 250ms;
  height: 100%;
  width: 100%;
}

.subitem-active {
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #6952de;
}
