.input-wrapper {
  border-width: 1px;
  border-style: solid;
  border-color: var(--redo-colors-border-border-primary);
  &.error {
    border-color: var(--redo-colors-border-border-error);
  }
  box-shadow: 0px 1px 2px 0px #1018280d;
  transition:
    border-color var(--redo-state-duration),
    outline-width var(--redo-state-duration);

  &.readonly {
    border-width: 0px;
  }

  border-radius: var(--redo-spacing-md);
  &.small {
    border-radius: var(--redo-spacing-xs);
  }

  &:focus-within {
    border-color: var(--redo-colors-border-border-brand);
    outline: 4px solid var(--redo-primary-extra-light-color);
    &.error {
      outline-color: var(
        --redo-component-colors-utility-error-utility-error-100
      );
      border-color: var(--redo-colors-border-border-error);
    }
  }

  &.disabled {
    background-color: var(--redo-colors-background-bg-disabled_subtle);
    border-color: var(--redo-colors-border-border-disabled);
    color: var(--redo-colors-text-text-disabled);
    cursor: not-allowed;
  }

  &.small {
    height: 28px;
  }
  &.medium {
    height: 40px;
  }
  &.large {
    height: 44px;
  }
}

.input {
  &::placeholder {
    color: var(--redo-colors-text-text-placeholder);
  }
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}

.icon-wrapper {
  &.error {
    color: var(--redo-colors-foreground-fg-error-secondary);
  }
  &.small {
    > svg {
      height: 12px;
      width: 12px;
    }
  }
  &.medium,
  &.large {
    > svg {
      height: 20px;
      width: 20px;
    }
  }
}

.leading-icon-wrapper {
  composes: icon-wrapper;
  color: var(--redo-colors-foreground-fg-quarterary-500);
}

.info-icon-wrapper {
  composes: icon-wrapper;
  color: var(--redo-colors-foreground-fg-quinary-400);
}

.measure-box {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  > * {
    white-space: nowrap;
    overflow: visible;
  }
}
