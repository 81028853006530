.actions {
  transition: opacity 250ms ease;
  padding-right: 16px;
  &.border {
    border-right: 1px solid #ccc;
  }
}

.actions.collapse {
  opacity: 0;
}

.actions-container:empty {
  display: none;
}

.actions-container {
  min-width: fit-content;
}

.content {
  flex: 1;
  /**
   * These values should be kept in sync with theme/misc.css
   */
  padding: 24px 48px 32px 48px;
  width: 100%;
  min-height: 0;

  &.hide-padding {
    padding: 0;
  }

  &.fullscreen {
    padding: 0;
  }

  &.opt-out-top-padding {
    padding-top: 0;
  }
  overflow-y: auto;
}

.content-child {
  /**
   * These values should be kept in sync with theme/misc.css
   */
  left: 48px !important;
  right: 48px !important;
  min-width: 0;
  &.child-full-height {
    height: 100%;
  }
}

.header {
  --padding-y: calc(var(--redo-spacing) * 3);
  box-shadow: transparent 0 1px 4px;
  padding: var(--padding-y) 48px;
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  align-items: center;
  transition:
    box-shadow var(--redo-state-duration),
    padding 100ms ease;
  height: 100%;
  &.with-tabs {
    align-items: flex-start;
    position: relative;
  }

  &.height-auto {
    height: auto;
  }

  border-bottom: 1px solid var(--redo-colors-border-border-primary);

  &:not(.scrolled-top) {
    box-shadow: rgba(0, 0, 0, 0.15) 0 1px 4px;
  }

  &.compact {
    --padding-y: calc(var(--redo-spacing) * 2);
  }

  &.hide-header-border {
    border-style: none;
  }
}

.header-container {
  height: 76px;
  top: 0;
  z-index: 10;
  background-color: var(--redo-colors-background-bg-primary);

  &.with-tabs {
    height: 124px; /* Increased height to accommodate tabs */
  }

  &.height-auto {
    height: auto;
  }
}

.no-scroll-x {
  min-width: unset;
  width: 100%;
}

.no-scroll-y {
  height: 100%;
}

.profile {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  line-height: 1;
}

.profile-container {
  position: relative;
}

.profile-image {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  object-fit: contain;
  background: white;
  overflow: hidden;
}

.profile-image > * {
  flex: 1;
}

.profile-main {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 6px;
}

.profile-title {
  font-family: var(--redo-title-font-family);
  font-size: var(--redo-body-large-text-size);
  color: #181818;
}

.profile-subtitle {
  font-size: var(--redo-body-small-text-size);
  color: #181818;
  opacity: 0.7;
}

.title {
  margin-right: auto;
  min-width: 0;
}

@media screen and (max-width: 600px) {
  .content {
    min-width: 900px;
  }
}

.tabs-wrapper {
  position: absolute;
  bottom: 0;
  left: 48px;
  right: 0px;
}
