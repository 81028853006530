.icon-highlight {
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 50%;
    height: 50%;
  }
}

.light {
  border-radius: 50%;
}

.xxs {
  width: 16px;
  height: 16px;
}

.xs {
  width: 24px;
  height: 24px;
}

.sm {
  width: 32px;
  height: 32px;
}

.md {
  width: 40px;
  height: 40px;
}

.lg {
  width: 48px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
}

.xl {
  width: 56px;
  height: 56px;
}

.dark,
.modern {
  &.xxs,
  &.xs,
  &.sm {
    border-radius: 6px;
  }

  &.md {
    border-radius: 8px;
  }

  &.lg {
    border-radius: 10px;
  }

  &.xl {
    border-radius: 12px;
  }
}

.modern {
  border: 1px solid var(--redo-colors-border-border-primary);
}
