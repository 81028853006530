.skeleton {
  background-color: var(--redo-colors-border-border-primary);
}

@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

.skeleton-text {
  color: transparent;
  width: fit-content;

  border-radius: 4px;
  height: 18px;
  background: linear-gradient(
    to right,
    var(--redo-colors-background-bg-quaternary) 20%,
    var(--redo-colors-background-bg-tertiary) 50%,
    var(--redo-colors-background-bg-quaternary) 80%
  );
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
