.button {
  cursor: pointer;
  border-radius: var(--redo-corner-small-radius);
  border: var(--redo-border-width) solid #ccc;
  overflow: hidden;
  transition: border 250ms;
}

.button:hover {
  border-color: var(--redo-primary-color);
}

.button:hover .image {
  opacity: 0.75;
}

.preview {
  width: 96px;
  height: 96px;
  object-fit: cover;
  transition: opacity 250ms;
}

.image {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 85%;
  max-height: 85%;
  box-shadow: 0 0 8px rgba(32, 32, 32, 0.5);
}
