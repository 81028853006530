.add-fee-button {
  width: fit-content;
}

.price-input {
  width: 100px;
}

.remove-fee-button-icon {
  color: var(--redo-colors-foreground-fg-error-secondary);
}

.remove-fee-button {
  width: 24px;
}

.subtitle {
  color: var(--redo-colors-text-text-tertiary-600);
}

.price-input-prefix {
  color: var(--redo-colors-text-text-tertiary-600);
}
