.timeline {
  --point-size: 9px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.event {
  font-size: var(--redo-body-small-text-size);
  display: flex;
  gap: var(--redo-spacing);
  z-index: 1;
}

.event-main {
  min-width: 0;
  flex: 1;
}

.line {
  --width: 1px;
  position: absolute;
  bottom: calc(var(--redo-line-height) * 1em * 1.5);
  top: calc(var(--redo-line-height) * 1em / 2);
  left: calc((var(--point-size) - var(--width)) / 2);
  width: var(--width);
  background-image: linear-gradient(#777 50%, transparent 0);
  background-position: right;
  background-size: 1px 8px;
  background-repeat: repeat-y;
}

.additional {
  color: #555;
  cursor: pointer;
  margin-left: calc(var(--point-size) + var(--redo-spacing));
  font-size: var(--redo-body-small-text-size);
  transform: color var(--redo-state-duration);

  svg {
    height: 14px;
    width: 14px;
    transform: rotate(-90deg);
    margin-left: -3px;
    margin-top: -2px;
    vertical-align: middle;
  }
}

.additional:hover {
  color: var(--redo-colors-base-black);
}

.title {
  composes: truncate from "./wrap.module.css";
}

.subtitle {
  color: var(--redo-colors-text-text-disabled);
  composes: truncate from "./wrap.module.css";
}

.point {
  width: var(--point-size);
  height: var(--point-size);
  border: 2px solid black;
  background: var(--redo-colors-background-bg-primary);
  border-radius: 50%;
  margin-top: calc((var(--redo-line-height) * 1em - var(--point-size)) / 2);
}
