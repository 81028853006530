.card {
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  color: var(--redo-colors-text-text-tertiary-600);

  &.dark {
    background-color: var(--redo-surface-dark-color);
  }

  &.scrollable {
    overflow: auto;
  }

  &.no-padding {
    padding: 0;
    gap: 0;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: calc(var(--redo-spacing) * 2);
}

.button-bar {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}

.collapse-header {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 4px;
  margin: -16px;
  padding: 16px;
}

.collapse-trigger {
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  gap: 4px;
  color: var(--redo-colors-text-text-disabled);

  &.inherit {
    color: inherit;
  }
}

.collapse-title {
  font-weight: bold;
  font-size: var(--redo-body-medium-text-size);
}

.collapse-section {
  overflow-y: hidden;
  transition: height var(--redo-collapse-duration) ease;
}

.collapse-section.collapsed .collapse-icon {
  transform: rotate(-90deg);
}

.collapse-icon {
  height: 16px;
  width: 16px;
  transition: transform 400ms;
}

.header-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
@media screen and (max-width: 700px) {
  .header-container {
    flex-direction: column;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1; /* TODO(devin): will this mess anything up? */
}

.header-title {
  font-family: var(--redo-title-font-family);
  color: var(--redo-colors-text-text-secondary-700);
  width: 100%;
}

.card.large .header-title {
  font-size: var(--redo-title-large-text-size);
  line-height: 24px;
}

.card.medium .header-title {
  font-size: var(--redo-title-medium-text-size);
  line-height: 20px;
}

.header-subtitle {
  font-family: var(--redo-body-font-family);
  font-size: var(--redo-body-small-text-size);
  line-height: 18px;
  color: var(--redo-colors-text-text-secondary-700);
}

.table {
  width: 100%;
  padding-top: 12px;
}

.table-header {
  font-size: var(--redo-body-medium-text-size);
  padding: 12px 8px 12px 0;
  text-align: left;
}

.table-cell {
  font-size: var(--redo-body-medium-text-size);
  padding: 12px 0 12px 8px;
  text-align: right;
}

.section {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  transition: height var(--redo-collapse-duration) ease;
  overflow-y: hidden;
}

.section.outlined {
  outline: 1px solid #eee;
  border-radius: 16px;
  padding: 16px;
}

.section.outlined.selected {
  outline-color: var(--redo-primary-color);
  border-radius: 16px;
}

.separator {
  border-bottom: 1px solid var(--redo-contrast-low-text-color) 7;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subsection {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.subtitle {
  font-weight: bold;
  font-size: var(--redo-body-small-text-size);
}

.title {
  font-weight: bold;
  font-size: var(--redo-body-medium-text-size);
}

.body {
  font-size: var(--redo-body-small-text-size);
}

.bg-image {
  border-radius: 16px;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.subtotal-collapse-header {
  cursor: pointer;
}

.subtotal-collapse-header-title {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 6px;
}

.subtotal-item {
  display: flex;
  justify-content: space-between;
  &.center {
    align-items: center;
    width: 100%;
  }
  &.left {
    align-items: flex-start;
    width: 100%;
  }
}

.close-button {
  width: 20px;
  height: 20px;
  color: #a3a3a3;
}

.expandable-card-title {
  cursor: pointer;

  .chevron {
    height: 14px;
    width: 14px;
    transition: transform var(--redo-state-duration);
  }
}

.expandable-card {
  transition: max-height var(--redo-state-duration);
  overflow: hidden;
}
