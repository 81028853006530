.checkbox-card {
  width: 100%;
  padding: 16px;
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 12px;
  cursor: pointer;
  transition: all var(--redo-state-duration) ease;
}

.checkbox-card:hover {
  border-color: var(--redo-colors-text-text-secondary_hover);
}

.checkbox-card-selected {
  border: 1px solid var(--redo-colors-text-text-secondary_hover);
}

.card-icon {
  width: 32px;
  height: 32px;
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
