.light {
  &.black {
    color: var(--redo-colors-gray-light-mode-100);
    background-color: var(--redo-colors-gray-light-mode-950);
  }
  &.success {
    color: var(--redo-component-colors-utility-success-utility-success-700);
    background-color: var(
      --redo-component-colors-utility-success-utility-success-50
    );
  }
  &.gray {
    color: var(--redo-colors-gray-light-mode-500);
    background-color: var(--redo-colors-gray-light-mode-200);
  }
  &.brand {
    color: var(--redo-colors-gray-light-mode-600);
    background-color: var(--redo-colors-gray-light-mode-100);
  }
  &.error {
    color: var(--redo-component-colors-utility-error-utility-error-700);
    background-color: var(
      --redo-component-colors-utility-error-utility-error-50
    );
  }
  &.warning {
    color: var(--redo-component-colors-utility-warning-utility-warning-600);
    background-color: var(
      --redo-component-colors-utility-warning-utility-warning-100
    );
  }
}

.dark {
  &.brand {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-gray-light-mode-600);
  }
  &.gray {
    color: var(--redo-colors-gray-light-mode-500);
    background-color: var(--redo-colors-gray-light-mode-200);
  }
  &.success {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-success-600);
  }
  &.error {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-error-600);
  }
  &.warning {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-warning-600);
  }
}

.modern {
  &.brand {
    border: 1px solid var(--redo-colors-gray-light-mode-300);
    color: var(--redo-colors-gray-light-mode-700);
    background-color: var(--redo-colors-base-white);
  }
}
