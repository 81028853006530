.textarea {
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 240px;
  overflow: hidden;
  white-space: pre-wrap;
  padding: 10px;
  resize: none;
  height: auto;
  background-color: #f5f5f5;
  font-size: 12px;
  flex-shrink: 0;
}

.content {
  position: absolute;
  bottom: 296px;
  left: 32px;
  z-index: 10;
  max-height: 394px;
  overflow: auto;
  width: 334px;
  padding: 24px;
}

.container {
  padding: 0 16px;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  max-height: min-content;
  align-self: center;
}

.sms-image {
  max-width: 240px;
  height: auto;
}

.phone-card {
  width: 100%;
  height: auto;
  max-height: fit-content;
  align-self: center;
}
