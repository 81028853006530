.drag-handle {
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    cursor: move;
    color: var(--redo-colors-background-bg-secondary-solid);
  }
}

.drag-handle-hovered {
  color: var(--redo-colors-background-bg-secondary-solid);
  cursor: grab;
}

.icon {
  width: 24px;
  height: 24px;
}

.column-item {
  max-height: 100%;
  width: 13rem;

  &:hover {
    background-color: var(--redo-colors-background-bg-primary_hover);
  }
}

.dropdown {
  width: 14rem;
  min-width: 14rem;
}
