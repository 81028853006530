.images {
  display: flex;
  flex-wrap: wrap;
  gap: var(--redo-spacing);
}

.pill-content {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.pill-icon {
  width: 12px;
  height: 12px;
}

.sales-channel-wrapper {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
