.input {
  width: 100%;
  font-size: var(--redo-body-xsmall-text-size);
  font-weight: var(--redo-font-weight-regular);
  padding: 0px 4px;
  &.border {
    border: var(--redo-border-width) solid
      var(--redo-colors-border-border-primary);
    border-radius: var(--redo-corner-small-radius);
    padding: var(--redo-spacing);
  }
  &.hide {
    display: none;
  }
}

.list {
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
