.action-button {
  min-width: 150px;
  max-height: 50px;
}

.action-buttons {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  padding: 16px;
  margin-left: auto;
}

.animation-container {
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-icon {
  vertical-align: text-bottom;
  margin-left: var(--redo-spacing);
}

.button-content {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  justify-content: center;
}

.button-content-print-barcodes {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  justify-content: center;
  width: 121px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
}

.card-title-container {
  display: flex;
}

.icon {
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
}

.code {
  display: inline-block;
  font-size: var(--redo-body-xsmall-text-size);
  background: lightyellow;
  border-radius: 2px;
  margin-left: 8px;
  padding: 2px 8px;
  margin-top: -2px;
  color: var(--redo-colors-base-black);
}

.column1 {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  flex: 2;
  min-width: 0;
}

.column2 {
  display: flex;
  min-width: 0;
  flex: 1;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
}

.container {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  flex: 1;
}

.no-conversations {
  padding: 16px;
}

.select-items-modal-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.select-items-modal-right-buttons {
  display: flex;
  margin-left: auto;
  gap: var(--redo-spacing);
}

.center-buttons {
  justify-content: center;
  width: 100%;
}

.edit-item {
  display: flex;
}

.help-text {
  font-size: var(--redo-body-xsmall-text-size);
  color: var(--redo-colors-text-text-disabled);
}

.edit-icon {
  height: 12px;
  margin-right: -7px;
  margin-bottom: -2px;
  width: 25px;
}

.button-bar {
  display: flex;
  align-items: right;
  justify-content: right;
  gap: var(--redo-spacing);
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header-subtitle {
  margin-top: var(--redo-spacing);
}

.process-item-subheader {
  display: flex;
  align-items: center;
}

.modal-product {
  border-radius: var(--redo-corner-medium-radius);
  border: solid #a6a9ba var(--redo-border-width);
  padding: calc(var(--redo-spacing) * 2);
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
}

.clickable {
  cursor: pointer;
}

.checkbox {
  display: flex;
  align-items: center;
  zoom: 1.25;
}

.adjustment {
  padding: var(--redo-spacing) calc(var(--redo-spacing) * 2);
  margin: var(--redo-spacing) 0;
  border: solid var(--redo-colors-border-border-secondary)
    var(--redo-border-width);
  border-radius: var(--redo-corner-medium-radius);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.restock {
  display: flex;
  align-items: end;
  gap: 4px;
  cursor: pointer;
  padding-bottom: 10px;
}

.restock-switch {
  /* Handle pointer events in the parent (restock) */
  pointer-events: none;
}

.no-transition * {
  transition: left 0ms !important;
}

.modal-product-info {
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: calc(var(--redo-spacing) * 2);
}

.error {
  margin-top: -10px;
  display: flex;
  width: 100%;
  justify-content: right;
  color: rgb(255, 81, 81);
}

.modal-product-info-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  padding-top: 5px;
  text-align: right;
}

.modal-product-info-right-bottom {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 30px;
  align-items: center;
}

.modal-image {
  border-radius: 10px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.chevron {
  height: 18px;
  cursor: pointer;
  color: var(--redo-home-text-color);
}

.chevron-disabled {
  height: 18px;
}

.chevron:hover {
  color: var(--redo-primary-color);
}

.new-address {
  /* display: flex; */
  /* align-items: center; */
  /* gap: var(--redo-spacing); */
  h4 {
    font-size: 14px;
    display: inline;
  }
  address {
    font-size: 12px;
  }
  p {
    font-size: 12px;
  }
  a {
    font-size: 12px;
  }
}

.item-index-text {
  color: var(--redo-home-text-color);
}

.item {
  display: flex;
  align-items: flex-start;
  gap: calc(var(--redo-spacing) * 3);
  border-top: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  padding-top: 16px;
}

.item:last-child {
  padding-bottom: 16px;
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.item-extra {
  width: fit-content;
}

.item-image {
  width: 96px;
  height: 96px;
  min-width: 96px;
  min-height: 96px;
  object-fit: cover;
  border-radius: var(--redo-corner-medium-radius);
}

.item-main {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  flex: 1;
}

.item-properties {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: var(--redo-body-xsmall-text-size);
  max-width: 350px;
}

.item-property {
  display: flex;
  gap: 6px;
  align-items: center;
}

.item-property-name {
  font-weight: bold;
  justify-self: end;
  display: flex;
  align-items: center;
}

.item-property-name::after {
  content: ":";
}

.item-property-value {
  color: var(--redo-colors-text-text-disabled);
}

.item-header {
  display: flex;
  gap: var(--redo-spacing);
  align-items: baseline;
  justify-content: space-between;
}

.item-title {
  font-weight: bold;
  display: flex;
  align-items: baseline;
  gap: var(--redo-spacing);
}

.item-subtitle {
  font-size: var(--redo-body-xsmall-text-size);
  color: var(--redo-colors-text-text-disabled);
}

.item-options {
  display: flex;
  gap: var(--redo-spacing);
  margin-top: -4px;
}

.item-options,
.item-options-text {
  color: var(--redo-colors-text-text-disabled);
  font-size: var(--redo-body-xsmall-text-size);
}

.item-title-icon {
  height: 1em;
  margin-top: -2px;
}

.item-option:not(:last-child) {
  padding-right: 8px;
  border-right: 1px solid var(--redo-colors-text-text-disabled);
}

.item-type {
  flex: 1;
  flex-basis: auto;
  font-weight: normal;
}

.item-price-edit {
  display: flex;
  justify-content: right;
  gap: var(--redo-spacing);
  align-items: baseline;
  overflow: hidden;
}

.item-price {
  display: inline-flex;
}

.edit-new-items-link {
  color: var(--redo-primary-color);
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.add-icon {
  padding-top: 4px;
}

.original-price {
  display: flex;
  width: 100%;
  position: relative;
  font-size: var(--redo-body-xsmall-text-size);
}

.right {
  justify-content: right;
}

.summary-text {
  font-weight: 500;
  font-size: var(--redo-body-small-text-size);
}

.summary-text-light {
  font-weight: normal;
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-contrast-low-text-color);
  margin-left: calc(var(--redo-spacing) * 2);
}

.item-price-container {
  height: 0px;
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
}

.item-value {
  font-weight: bold;
  margin-left: auto;
  flex: 1;
  text-align: right;
  margin-left: 10px;
}

.item-summary-line {
  width: 40%;
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.item-summary-value {
  font-size: 13px;
}

.option-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menu-title {
  font-size: var(--redo-body-small-text-size);
  font-weight: bold;
  padding: 4px 16px;
  color: #555760;
}

.option-content {
  display: flex;
  gap: var(--redo-spacing);
  align-items: center;
  padding-left: 24px;
  width: 100%;
  height: 100%;
}

.price-summary-title {
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.add-item {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.item-search {
  height: 400px;
  overflow: scroll;
}

.voided {
  color: var(--redo-colors-text-text-disabled);
  font-weight: normal;
  text-decoration: line-through !important;
}

.adjustment-bonus {
  display: flex;
  padding: 3px 11px;
  background-color: var(--redo-primary-color);
  color: var(--redo-text-color);
  border-radius: 50px;
  font-size: var(--redo-body-xsmall-text-size);
  margin-top: -2px;
}

.adjustment-fee {
  display: flex;
  padding: 3px 11px;
  background-color: #555760;
  color: #fff;
  border-radius: 50px;
  font-size: var(--redo-body-xsmall-text-size);
  margin-top: -2px;
}

.new-item-header {
  display: flex;
  align-items: baseline;
}

.empty-exchange-order {
  text-align: center;
  font-size: var(--redo-body-medium-text-size);
  color: var(--redo-colors-text-text-disabled);
  padding: 10px;
}

.green-return-adjustment {
  display: flex;
  padding: 3px 11px;
  background-color: #3f7afe;
  color: #fff;
  border-radius: 50px;
  margin-top: -2px;
}

.green-return-select-container {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  align-items: center;
}

.green-return-select-title {
  font-size: 14px;
  font-weight: bold;
  color: #464646;
}

.green-return-text {
  font-size: 14px;
  font-weight: 300;
  color: #464646;
}

.green-return-image {
  border-radius: 10px;
  width: 56px;
  height: 56px;
  object-fit: cover;
}

.modal-button {
  width: 150px;
}

.modal-footer-right {
  justify-content: right;
  display: flex;
  width: 100%;
}

.click-away-listener {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.return-type-modal {
  position: absolute;
  background-color: var(--redo-colors-background-bg-primary);
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  padding: 17px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  border: solid #a6a9ba var(--redo-border-width);
  width: 234px;
  font-weight: normal;
  z-index: 25;

  &.right-aligned {
    right: 15px;
  }
}

.return-type-modal-header {
  display: flex;
  justify-content: space-between;
}

.return-type-modal-button {
  background: rgba(166, 169, 186, 0.15);
  padding: 11px;
  border-radius: 15px;
  font-size: 12px;
  padding-left: 18px;
  transition: 0.25s;
  text-align: left;
}

.return-type-modal-button:hover {
  background: #555760;
  color: white;
}

.table {
  width: 100%;

  tr:not(:first-child) {
    td,
    th {
      padding-top: 6px;
    }
  }

  tr:not(:last-child) {
    td,
    th {
      padding-bottom: 6px;
    }
  }
}

.order-link {
  display: flex;
  flex-wrap: wrap;
  gap: var(--redo-spacing);
}

.product {
  flex: 2;
  display: flex;
  gap: var(--redo-spacing);
  align-items: baseline;
  flex-direction: column;
}

.status {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--redo-spacing) * 2);
  font-size: var(--redo-body-medium-font-size);
  vertical-align: text-top;
  gap: 8px;
}

.external {
  margin-right: calc(var(--redo-spacing) * 2);
}

.coverage {
  display: inline-block;
  font-size: calc(var(--redo-spacing) * 2);
  vertical-align: text-top;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  padding: var(--redo-spacing) 0;
  margin-top: calc(var(--redo-spacing) * -1.5);
}

.padded-modal-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  padding: 16px;
  overflow-y: auto;
}

.processButtons {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.table-header-group {
  display: flex;
  flex-direction: column;
}

.table-header {
  font-size: var(--redo-body-small-text-size);
  padding-right: 8px;
  text-align: left;
  font-weight: 500;
}

.table-description {
  font-size: var(--redo-body-xsmall-text-size);
  font-style: italic;
  padding-right: 8px;
  text-align: left;
}

.table-cell {
  font-size: var(--redo-body-small-text-size);
  padding-left: 8px;
  text-align: right;
  font-weight: 500;
}

.subsection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tracking-numbers {
  li {
    margin-left: 16px;
  }
}

.fraud-risk-container {
  display: flex;
  gap: var(--redo-spacing);
  font-size: var(--redo-body-small-text-size);
}

.risk-report-iframe {
  transition:
    height var(--redo-collapse-duration),
    width var(--redo-collapse-duration),
    min-width var(--redo-collapse-duration),
    max-width var(--redo-collapse-duration);
  top: 50%;
  left: 50%;
  position: absolute;
}

.full-width {
  width: 100%;
}
.full-height {
  height: 46px;
}

.create-labels-image {
  border-radius: 10px;
  width: 40px;
  height: 40px;
}

.item-card {
  width: 200px;
  background-color: var(--redo-colors-background-bg-tertiary);
}

.no-text-wrap {
  text-wrap: nowrap;
}

.trash {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.tooltip-link {
  text-decoration: underline;
}

.delete-icon-container {
  height: 24px;
}

.new-items-section {
  overflow: visible;
}

.address-space {
  margin-top: 16px;
}
