.main {
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--redo-colors-border-border-secondary);
  position: absolute;
  font-size: 18px;
  line-height: 28px;
  background-color: var(--redo-colors-background-bg-primary);
  color: var(--redo-colors-base-black);
  box-shadow: 0px 1px 2px 0px #1018280d;

  &.horizontal {
    flex-direction: row;
  }
  &.vertical {
    flex-direction: column;
  }
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px;
  transition: background-color var(--redo-state-duration);
  z-index: 16;
  &:hover {
    background-color: #d6d6d655;
  }

  &.divider-right {
    border-right: 1px solid #e5e5e5;
  }

  &.divider-bottom {
    border-bottom: 1px solid #e5e5e5;
  }
}

.click-away-listener {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 15;
}
