.summary-date {
  font-size: var(--redo-body-xsmall-text-size);
  color: var(--redo-colors-text-text-disabled);
}

.summary-info {
  display: flex;
  align-items: baseline;
}

.summary-info-section {
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 0.5);
}
