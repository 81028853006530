.dropdown-item-wrapper {
  display: flex;
  flex-direction: column;
}

:global(.MuiAutocomplete-root) {
  background-color: var(--redo-colors-background-bg-primary) !important;
}

:global(.MuiInputBase-input) {
  color: var(--redo-colors-text-text-primary-900) !important;
}

:global(.MuiInputBase-root) {
  color: var(--redo-colors-text-text-primary-900) !important;
  &:hover {
    background-color: var(--redo-colors-background-bg-primary_hover) !important;
  }
  &:focus-within {
    background-color: var(--redo-colors-background-bg-primary_hover) !important;
  }
}

:global(.MuiButtonBase-root svg) {
  stroke: var(--redo-colors-text-text-primary-900) !important;
}
